import React from 'react';
import cn from 'classnames';
import s from './DevelopmentPlan.module.css';

const DevelopmentPlan = () => {
  return (
    <div className={s.developmentPlan} id="developmentPlan">
      <div className="container">
        <h2 className={s.developmentPlanTitle}>План развития</h2>
        <p className={s.developmentPlanDescription}>В планах развития RT Life</p>
        <div className={s.developmentPlanItemGroups}>
          <ul className={cn(s.developmentPlanItemGroup, s.groupGray)}>
            <li className={s.developmentPlanItemGroupItem}>
              Настройка и вывод на главный экран виджетов
            </li>
            <li className={s.developmentPlanItemGroupItem}>
              Реализация механизма электронно&#65279;-&#65279;цифровой&nbsp;подписи
            </li>
            <li className={s.developmentPlanItemGroupItem}>Создание среды для Karma Framework</li>
          </ul>
          <ul className={cn(s.developmentPlanItemGroup, s.groupPurple)}>
            <li className={s.developmentPlanItemGroupItem}>
              Разработка сервиса для комфортной работы рекрутеров с соискателями
            </li>
            <li className={s.developmentPlanItemGroupItem}>Обновление технологического стека</li>
            <li className={s.developmentPlanItemGroupItem}>
              Улучшение UX/UI (переход к Material Design)
            </li>
          </ul>
          <ul className={cn(s.developmentPlanItemGroup, s.groupGreen)}>
            <li className={s.developmentPlanItemGroupItem}>Разработка сервиса пребординга</li>
            <li className={s.developmentPlanItemGroupItem}>
              Разработка сервиса онбординга сотрудников&nbsp;и чек-листов новичков
            </li>
          </ul>
          <ul className={cn(s.developmentPlanItemGroup, s.groupOrange)}>
            <li className={s.developmentPlanItemGroupItem}>
              Интеграция со всеми внутренними системами&nbsp;компании с целью полного отказа&nbsp;от
              бумажного документооборота
            </li>
            <li className={s.developmentPlanItemGroupItem}>Доработка таск-трекера по заявкам</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentPlan;
