import React, { useState } from 'react';
import Slider from 'react-slick';
import Modal from 'react-modal';
import { sliderData, functionsListData } from './data';
import Arrow from '../Arrow';
import Progressbar from '../Progressbar';
import s from './FunctionsList.module.css';

const FunctionsList = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('Пусто');
  const [modalTitle, setModalTitle] = useState('Пусто');

  const settings = {
    swipe: true,
    fade: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1000,
    pauseOnHover: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    dotsClass: 'dots',
    customPaging: () => <Progressbar stepWidth={120} />,
    className: s.functionsSlider,
    nextArrow: <Arrow bigOffset />,
    prevArrow: <Arrow back bigOffset />,
    responsive: [
      {
        breakpoint: 1919,
        settings: {
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 1.8,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1,
          customPaging: () => <Progressbar stepWidth={70} />,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          customPaging: () => <Progressbar stepWidth={40} />,
        },
      },
    ],
  };

  const openModal = (item) => {
    setShowModal(true);
    setModalTitle(item.title);
    setModalText(item.fullDescription);
  };

  return (
    <div className={s.functions} id="functions">
      <div className="container">
        <h2 className={s.functionsTitle}>
          Основные
          <br />
          функции
        </h2>
      </div>
      <div className={s.sliderWrapper}>
        <Slider {...settings}>
          {sliderData.map((item) => (
            <div key={item.id} className={s.functionsSliderItemOuter}>
              <div className={s.functionsSliderItemInner}>
                <div className={s.functionsSliderItemText}>
                  <h2
                    className={s.sliderItemTitle}
                    style={{ background: `url(${item.icon}) no-repeat left top` }}
                  >
                    {item.title}
                  </h2>
                  <p
                    className={s.sliderItemDescription}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  <button
                    type="button"
                    className={s.sliderItemButton}
                    onClick={() => openModal(item)}
                  >
                    Подробнее
                  </button>
                </div>
                <div className={s.sliderItemImageWrapper}>
                  <img src={item.image} className={s.sliderItemImage} alt="" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="container">
        <ul className={s.functionsList}>
          {functionsListData.map((item) => {
            return (
              <li key={item.id} className={s.functionsListItemOuter}>
                <div
                  className={s.functionsListItemInner}
                  onClick={() => openModal(item)}
                  onKeyPress={() => openModal(item)}
                  role="button"
                  tabIndex="0"
                >
                  <div className={s.functionsItemImageWrapperOuter}>
                    <div className={s.functionsItemImageWrapperInner}>
                      <img src={item.image} className={s.functionsItemImage} alt="" />
                    </div>
                  </div>
                  <div className={s.functionsListText}>
                    <h2 className={s.functionsListTitle}>{item.title}</h2>
                    <p
                      className={s.functionsListDescription}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <Modal
        isOpen={showModal}
        contentLabel="Модальное окно"
        onRequestClose={() => setShowModal(false)}
        className={s.modal}
        overlayClassName={s.modalOverlay}
        ariaHideApp={false}
      >
        <h3 className={s.modalTitle}>{modalTitle}</h3>
        {/* eslint-disable-next-line react/no-danger */}
        <p className={s.modalText} dangerouslySetInnerHTML={{ __html: modalText }} />
        <button
          type="button"
          aria-label="Close"
          onClick={() => setShowModal(false)}
          className={s.modalClose}
        />
      </Modal>
    </div>
  );
};

export default FunctionsList;
