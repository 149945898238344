import React from 'react';
import Slider from 'react-slick';
import cn from 'classnames';
import Arrow from '../Arrow';
import Progressbar from '../Progressbar';
import s from './StoreFeedbacks.module.css';
import { sliderData } from './data';

const StoreFeedbacks = () => {
  const settings = {
    swipe: true,
    fade: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1000,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 2,
    dotsClass: 'dots',
    customPaging: () => <Progressbar stepWidth={224} />,
    className: s.storeFeedbacksSlider,
    nextArrow: <Arrow />,
    prevArrow: <Arrow back />,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          customPaging: () => <Progressbar stepWidth={184} />,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          customPaging: () => <Progressbar stepWidth={70} />,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          customPaging: () => <Progressbar stepWidth={30} />,
        },
      },
    ],
  };

  return (
    <div className={s.storeFeedbacks} id="storeFeedbacks">
      <div className="container">
        <h2 className={s.storeFeedbacksTitle}>
          Отзывы в App Store <br className={s.mobileHidden} />и Google Play
        </h2>
      </div>
      <div className={s.sliderWrapper}>
        <Slider {...settings}>
          {sliderData
            .sort((a, b) => a.id - b.id)
            .map((item) => (
              <div key={item.id} className={s.storeFeedbacksSliderItemOuter}>
                <div className={s.storeFeedbacksSliderItemInner}>
                  <div>
                    <p
                      className={cn(
                        s.sliderItemTitle,
                        item.stars === 5 ? s.fiveStars : s.fourStars,
                      )}
                    >
                      {item.title}
                    </p>
                    <p className={s.sliderItemDescription}>{item.description}</p>
                  </div>

                  <p className={s.sliderItemInfo}>{item.info}</p>
                </div>
              </div>
            ))}
          <div className={s.storeFeedbacksSliderItemOuter}>
            <div className={s.storeFeedbacksSliderItemInner}>
              <div className={cn(s.sliderItemImage, s.appStore)} />
              <p className={s.sliderItemStoreText}>Больше отзывов в App store</p>
              <a
                href="https://apps.apple.com/ru/app/rt-life/id1435309319"
                className={s.sliderItemStoreLink}
              >
                Смотреть все
              </a>
            </div>
          </div>
          <div>
            <div className={s.storeFeedbacksSliderItemOuter}>
              <div className={s.storeFeedbacksSliderItemInner}>
                <div className={cn(s.sliderItemImage, s.googlePlay)} />
                <p className={s.sliderItemStoreText}>Больше отзывов в Google play</p>
                <a
                  href="https://play.google.com/store/apps/details?id=ru.rt.life&hl=ru"
                  className={s.sliderItemStoreLink}
                >
                  Смотреть все
                </a>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default StoreFeedbacks;
