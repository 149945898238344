import React from 'react';
import cn from 'classnames';
import screen from '../../images/main-screen.png';
import appStoreMobile from '../../images/app-store-button-mobile.svg';
import appStore from '../../images/app-store-button.svg';
import googlePlayMobile from '../../images/google-play-button-mobile.svg';
import googlePlay from '../../images/google-play-button.svg';
import s from './Screenshot.module.css';

const Screenshot = () => {
  return (
    <div className={s.screenshotBlock}>
      <div className={cn(s.screenshotBlockInner, 'container')}>
        <div className={s.screenshotTextButtonsBlock}>
          <div className={s.screenshotTextBlock}>
            <h1 className="visually-hidden">RT Life</h1>
            <h2 className={s.screenshotText}>
              RT&nbsp;Life&nbsp;— это инструмент для&nbsp;деловых коммуникаций сотрудников
              Ростелекома.
            </h2>
            <br />
            <p className={s.screenshotSmallText}>
              Для доступа к&nbsp;рабочим контактам, документам и&nbsp;прочим жизненно
              важным&nbsp;вещам.
            </p>
          </div>
          <div className={s.storeButtonsBlock}>
            <a
              href="https://apps.apple.com/ru/app/rt-life/id1435309319"
              target="_blank"
              rel="noopener noreferrer"
              className={s.storeButton}
            >
              <img className={s.storeButtonImageMobile} src={appStoreMobile} alt="appStore" />
              <img className={s.storeButtonImage} src={appStore} alt="appStore" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=ru.rt.life&hl=ru"
              target="_blank"
              rel="noopener noreferrer"
              className={s.storeButton}
            >
              <img className={s.storeButtonImageMobile} src={googlePlayMobile} alt="googlePlay" />
              <img className={s.storeButtonImage} src={googlePlay} alt="googlePlay" />
            </a>
          </div>
        </div>
        <div className={s.screenshots}>
          <img src={screen} className={s.screen} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Screenshot;
