export const sliderData = [
  {
    id: 2,
    title: '',
    description:
      'Отличное приложение, только ещё добавьте пожалуйста возможность просматривать расчётный лист прямо в приложении.',
    info: 'Sergey from Tyumen, 20 апреля 2021 г.',
    stars: 5,
  },
  {
    id: 4,
    title: '',
    description:
      'Классное обновление, спасибо за заказ справки 2НДФЛ, очень удобно. И очень нравится новый интерфейс!',
    info: 'kolyaviktorov, 4 февраля 2021 г.',
    stars: 5,
  },
  {
    id: 5,
    title: '',
    description:
      'Наконец приложение, которое можно сконфигурировать под себя, это очень удобно! Жду дальнейшего развития!',
    info: 'k-k-h, 27 декабря 2020 г.',
    stars: 5,
  },
  {
    id: 1,
    title: '',
    description: 'Хорошо. Удобно в работе.',
    info: 'Игорь Филиппов, 27 апреля 2021 г.',
    stars: 5,
  },
  {
    id: 3,
    title: '',
    description:
      'Приложение работает отлично! При возникновении неполадок, ошибки решаются быстро.',
    info: 'Galina Pugaeva, 2 марта 2021 г.',
    stars: 5,
  },
  {
    id: 6,
    title: '',
    description: 'Классное обновление! Ждем остальных функций.',
    info: 'm0rte$, 24 декабря 2020 г.',
    stars: 5,
  },
];
