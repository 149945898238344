import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import Arrow from '../Arrow';
import Progressbar from '../Progressbar';
import s from './Statistics.module.css';
import { localSliderData } from './data';
import icon1 from '../../images/users.svg';
import icon2 from '../../images/mobile.svg';
import icon3 from '../../images/speedtest.svg';
import icon4 from '../../images/chat-white.svg';
import icon5 from '../../images/launch.svg';

const api = 'https://prod.rtlife.rt.ru'; // prod || test

const Statistics = () => {
  const [actualSliderData, setActualSliderData] = useState(null);

  const settings = {
    swipe: true,
    fade: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 10000,
    speed: 1000,
    pauseOnHover: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    dotsClass: 'dots',
    className: s.statisticsSlider,
    nextArrow: <Arrow gray />,
    prevArrow: <Arrow back gray />,
    responsive: [
      {
        breakpoint: 1439,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          customPaging: () => <Progressbar stepWidth={224} />,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          customPaging: () => <Progressbar stepWidth={70} />,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          customPaging: () => <Progressbar stepWidth={40} />,
        },
      },
    ],
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: `${api}/api/v1/landing/statistic`,
      headers: {
        'X-Auth-Token': 'fce296fb-977d-4de6-b40c-06263e94a5fd',
      },
    })
      .then((response) => {
        setActualSliderData(response.data.statistics);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, []);

  const thousandSeparator = (value) => {
    if (typeof value !== 'undefined') {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return null;
  };

  const getBackgroundColor = (type) => {
    switch (type) {
      case 'daily_activity':
        return '#9466FF';
      case 'year_activity':
        return '#7B939B';
      case 'installations':
        return '#4897DF';
      case 'rate':
        return '#57D9B7';
      case 'launch_months':
        return '#FF5E2D';
      default:
        return '#4897DF';
    }
  };

  const getBackgroundIcon = (type) => {
    switch (type) {
      case 'daily_activity':
        return icon1;
      case 'year_activity':
        return icon2;
      case 'installations':
        return icon3;
      case 'rate':
        return icon4;
      case 'launch_months':
        return icon5;
      default:
        return icon2;
    }
  };

  const getSortedData = (data) => data && data.sort((a, b) => a.order_number - b.order_number);

  const sliderData = getSortedData(actualSliderData) || getSortedData(localSliderData);

  return (
    <div className={s.statistics} id="statistics">
      <div className="container">
        <h2 className={s.statisticsTitle}>
          Статистика
          <br />
          приложения
        </h2>
      </div>
      <div className={s.sliderWrapper}>
        <Slider {...settings}>
          {sliderData.map((item) => (
            <div key={item.order_number + 1} className={s.statisticsSliderItemOuter}>
              <div
                className={s.statisticsSliderItemInner}
                style={{
                  background: `linear-gradient(340.77deg, rgba(16, 24, 40, 0.25) 5.01%, rgba(16, 24, 40, 0) 58.25%), ${getBackgroundColor(
                    item.type,
                  )}`,
                }}
              >
                <p
                  className={s.sliderItemTitle}
                  style={{
                    background: `url(${getBackgroundIcon(item.type)}) no-repeat left top`,
                  }}
                >
                  {item.type === 'rate' ? item.statistic / 10 : thousandSeparator(item.statistic)}
                </p>
                <p className={s.sliderItemDescription}>{item.title}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Statistics;
