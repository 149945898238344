import screen1 from '../../images/functions-screen-1.png';
import screen2 from '../../images/functions-screen-2.png';
import screen3 from '../../images/functions-screen-3.png';
import screen4 from '../../images/functions-screen-4.png';
import screen5 from '../../images/functions-screen-5.png';
import screen6 from '../../images/functions-screen-6.png';
import screen7 from '../../images/functions-screen-7.png';
import screen8 from '../../images/functions-screen-8.png';
import screen9 from '../../images/functions-screen-9.png';
import screen10 from '../../images/functions-screen-10.png';
import icon1 from '../../images/lightning.svg';
import icon2 from '../../images/contacts.svg';
import icon3 from '../../images/microphone.svg';
import icon4 from '../../images/document.svg';
import icon5 from '../../images/fire.svg';
import icon6 from '../../images/dashboard.svg';

export const sliderData = [
  {
    id: 1,
    title: 'Сторис и новости',
    description:
      'В&nbsp;вашей жизни больше не&nbsp;будет места бесконечным скучным лентам новостей! Лучшие моменты из&nbsp;жизни Ростелекома в&nbsp;модном формате &laquo;сторис&raquo;&nbsp;&mdash; читайте и&nbsp;смотрите только&nbsp;то, что вам действительно интересно.',
    fullDescription:
      'Вы&nbsp;можете следить за&nbsp;новостями &laquo;удаленки&raquo; и&nbsp;корпоративными мероприятиями, быть в&nbsp;курсе анонсов и&nbsp;событий, первыми узнавать о&nbsp;возможностях обучения и&nbsp;развития, а&nbsp;также много другое.',
    image: screen1,
    icon: icon1,
  },
  {
    id: 2,
    title: 'Адресная книга',
    description:
      'Как найти коллегу в&nbsp;огромной компании? Для этого в&nbsp;RT&nbsp;Life мы&nbsp;реализовали адресную книгу. Достоточно ввести фамилию, департамент или телефон сотрудника в&nbsp;строку поиска и&nbsp;выбрать из&nbsp;предлагаемого списка искомого человека.',
    fullDescription:
      'Доступен просмотр профиля сотрудника: его полное имя, фото, должность, департамент, контактные данные. Также вы&nbsp;увидите его непосредственного руководителя, подчиненных и&nbsp;коллег, статус (в&nbsp;отпуске сотрудник или нет) и&nbsp;участие в&nbsp;кругах Karma Framework.',
    image: screen2,
    icon: icon2,
  },
  {
    id: 3,
    title: 'Подкасты',
    description:
      'Еще один способ узнавать о&nbsp;достижениях компании&nbsp;&mdash; подкасты. Этот раздел можно найти на&nbsp;первой странице в&nbsp;&laquo;Ленте событий&raquo; и, при желании, добавить его в&nbsp;ваш дашборд.',
    fullDescription:
      'Вы&nbsp;можете выбрать выпуск по&nbsp;вкусу, прослушивать его в&nbsp;фоновом режиме или переходить сразу на&nbsp;интересующие моменты записи, используя подробное поминутное описание содержания выпуска подкаста.',
    image: screen3,
    icon: icon3,
  },
  {
    id: 4,
    title: 'Заявки в Helpme',
    description:
      'Help me&nbsp;&mdash; система для подачи заявок самого разного рода. В&nbsp;RT&nbsp;Life вы&nbsp;найдете все ваши заявки из&nbsp;Help me и&nbsp;сможете отслеживать&nbsp;их, оставаясь мобильными.',
    fullDescription:
      'Также мы&nbsp;реализовали возможность завести в&nbsp;Help me самые необходимые заявки, а&nbsp;именно: техническое обслуживание ноутбуков, компьютеров, планшетов и&nbsp;оргтехники, на предоставление удаленного доступа и&nbsp;решение проблем с&nbsp;ним.',
    image: screen4,
    icon: icon4,
  },
  {
    id: 5,
    title: 'Просмотр задач в ЕСЭД',
    description:
      'ЕСЭД&nbsp;&mdash; единая система электронного документооборота, которая является неотъемлемой частью работы компании. В&nbsp;RT&nbsp;Life доступен просмотр самых необходимых видов заявок, заведенных в&nbsp;ЕСЭД: на&nbsp;исполнение, на&nbsp;исправление или принятие отчета, на&nbsp;рассмотрение.',
    fullDescription:
      'Также вы&nbsp;можете создать поручение или отчет, перепоручить или завершить заявку, скачать и&nbsp;посмотреть все файлы, прикрепленные к&nbsp;заявке. Есть возможность назначить контролера и&nbsp;посмотреть задачи коллег, которых вы&nbsp;замещаете. ',
    image: screen5,
    icon: icon5,
  },
  {
    id: 6,
    title: 'Дашборд',
    description:
      'Главный экран представляет собой дашборд, на&nbsp;котором собраны все функциональные элементы, реализованные в&nbsp;виде виджетов.',
    fullDescription:
      'Каждый пользователь может настроить дашборд так, как ему нравится, включив отображение лишь часто используемых функций.',
    image: screen6,
    icon: icon6,
  },
];

export const functionsListData = [
  {
    id: 1,
    title: 'Заказ АКС',
    description:
      'При необходимости срочно организовать аудиоконференцию, вы&nbsp;можете также воспользоваться RT&nbsp;Life&nbsp;&mdash; достаточно лишь указать нужные параметры и&nbsp;нажать кнопку.',
    fullDescription:
      'В&nbsp;приложении реализована возможность заказа АКС с&nbsp;помощью специальной заявки&nbsp;&mdash; введите тему встречи, количество участников, интервал времени и&nbsp;в&nbsp;ответ вам будет выдан ПИН-код доступа. Вы&nbsp;также можете отправить приглашение гостям и&nbsp;добавить АКС в&nbsp;календарь.',
    image: screen7,
  },
  {
    id: 2,
    title: 'Справка 2-НДФЛ',
    description:
      'Если вы&nbsp;делаете визу или берете кредит в&nbsp;банке&nbsp;&mdash; вам понадобится справка 2-НДФЛ. Теперь ее можно получить прямо из&nbsp;мобильного приложения!',
    fullDescription:
      'Выберите необходимый период, укажите куда отправить, вам лично или представителю, задайте количество экземпляров справки&nbsp;&mdash; и&nbsp;просто ожидайте получения готового документа.',
    image: screen8,
  },
  {
    id: 3,
    title: 'Витрина стартапов',
    description:
      'Витрина стартапов&nbsp;&mdash; раздел, в&nbsp;котором собраны все стартапы компании. Чтобы получить доступ к&nbsp;нему, необходимо зарегистрироваться на&nbsp;сайте port.rt.ru.',
    fullDescription:
      'Вы&nbsp;можете посмотреть непосредственно описание стартапа, его текущий статус, данные ответственного специалиста и&nbsp;презентацию идеи.',
    image: screen9,
  },
  {
    id: 4,
    title: 'Скидки',
    description:
      'В&nbsp;разделе указаны все актуальные скидки для сотрудников, разделенные для удобства на&nbsp;категории: финансовые, медицинские, точки питания, красота и&nbsp;здоровье и&nbsp;т.&nbsp;д.',
    fullDescription:
      'Внутри конкретной скидки есть пояснения об&nbsp;условиях предоставления&nbsp;&mdash; по&nbsp;промо-коду, кодовому слову, по&nbsp;корпоративной дисконтной карте или в&nbsp;конкретной точке предоставления услуг.',
    image: screen10,
  },
];
