import React, { useState } from 'react';
import Select from 'react-select';
import cn from 'classnames';
import axios from 'axios';
import Modal from 'react-modal';
import { Form, Field } from 'react-final-form';
import s from './ContactInfo.module.css';

let errors = {};
const api = 'https://prod.rtlife.rt.ru'; // prod || test

const ContactInfo = () => {
  const [isDirty, setIsDirty] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onSubmit = async (values) => {
    await sleep(300);
    // console.log(JSON.stringify(values, 0, 2));

    const userData = {
      request_type:
        values.petitionReason?.value === 'Другое'
          ? values.anotherReason
          : values.petitionReason.value,
      username: values.name,
      email: values.email,
      body: values.message,
    };

    axios({
      method: 'post',
      url: `${api}/api/v1/landing/support_request`,
      data: userData,
      headers: {
        'Content-type': 'application/json',
        'X-Auth-Token': 'fce296fb-977d-4de6-b40c-06263e94a5fd',
      },
    })
      .then(() => {
        setShowModal(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setShowError(true);
        setIsLoading(false);
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  // eslint-disable-next-line react/prop-types
  const ReactSelectAdapter = ({ input, ...rest }) => <Select {...input} {...rest} />;

  return (
    <div className={s.contactInfo} id="contactInfo">
      <div className={cn(s.contactInfoInner, 'container')}>
        <div className={s.contactInfoText}>
          <h2 className={s.contactInfoTitle}>
            Контактная
            <br />
            информация
          </h2>
          <div className={s.contactInfoDescription}>
            <p className={s.contactDescriptionSubtitle}>Адрес</p>
            <p className={s.contactDescriptionSubtext}>
              г. Москва, Проспект&nbsp;Вернадского, д.&nbsp;41
            </p>
            <p className={s.contactDescriptionSubtitle}>Почта</p>
            <a className={s.contactDescriptionSubtext} href="mailto:life@rt.ru">
              life@rt.ru
            </a>
          </div>
          {/* Временно убран блок с документами */}
          {/* <div className={s.contactInfoDocuments}>
            <p className={s.documentsSubtitle}>Документация</p>
            <ul className={s.documentsList}>
              <li className={s.documentsListItem}>
                <a href="/Инструкция по установке ПО.pdf" target="_blank">
                  Инструкция по установке ПО
                </a>
              </li>
              <li className={s.documentsListItem}>
                <a href="/Руководство пользователя мп RT Life.pdf" target="_blank">
                  Руководство пользователя
                </a>
              </li>
            </ul>
          </div> */}
        </div>
        <div className={s.contactInfoSupport}>
          <div>
            <h3 className={s.contactInfoSupportTitle}>Запрос в службу поддержки</h3>
            <Form
              onSubmit={onSubmit}
              validate={(values) => {
                errors = {};
                if (!values.petitionReason || values.petitionReason === 'null') {
                  errors.petitionReason = 'Поле обязательно для заполнения';
                }
                if (values.petitionReason?.value === 'Другое' && !values.anotherReason) {
                  errors.anotherReason = 'Поле обязательно для заполнения';
                } else if (
                  values.petitionReason?.value === 'Другое' &&
                  values.anotherReason.length > 100
                ) {
                  errors.anotherReason = 'Превышена максимальная длина сообщения';
                }
                if (!values.email) {
                  errors.email = 'Поле обязательно для заполнения';
                } else if (!values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                  errors.email = 'Некорректный формат почты';
                }
                if (!values.name) {
                  errors.name = 'Поле обязательно для заполнения';
                } else if (values.name.length < 2) {
                  errors.name = 'Слишком короткое имя';
                } else if (values.name.length > 32) {
                  errors.name = 'Слишком длинное имя';
                }
                if (!values.message) {
                  errors.message = 'Поле обязательно для заполнения';
                } else if (values.message.length < 5) {
                  errors.message = 'Слишком короткое сообщение';
                } else if (values.message.length > 1000) {
                  errors.message = 'Превышена максимальная длина сообщения';
                }

                return errors;
              }}
              render={({ handleSubmit, form, submitting, values }) => (
                <form
                  onSubmit={(event) => {
                    handleSubmit(event).then(() => {
                      setShowError(null);
                      setIsLoading(true);
                      setIsDirty(false);
                    });
                  }}
                  autoComplete="off"
                >
                  <div className={s.contactInfoSupportInputWrapper}>
                    <Field
                      name="petitionReason"
                      component={ReactSelectAdapter}
                      options={[
                        { value: 'Ошибка в приложении', label: 'Ошибка в приложении' },
                        { value: 'Пожелания по функционалу', label: 'Пожелания по функционалу' },
                        { value: 'Вопрос в службу поддержки', label: 'Вопрос в службу поддержки' },
                        { value: 'Другое', label: 'Другое' },
                      ]}
                      isSearchable={false}
                      placeholder="Тип обращения"
                      onMenuClose={() => setIsDirty(true)}
                      className={s.contactInfoSupportSelect}
                    />
                    {isDirty && !values.petitionReason && (
                      <span className={s.contactInfoSupportInputError}>
                        Поле обязательно для заполнения
                      </span>
                    )}
                  </div>
                  {values.petitionReason?.value === 'Другое' && (
                    <Field name="anotherReason">
                      {({ input, meta }) => (
                        <div className={s.contactInfoSupportInputWrapper}>
                          <input
                            {...input}
                            type="text"
                            className={s.contactInfoSupportInput}
                            required
                          />
                          <span
                            className={cn(
                              s.floatingLabel,
                              meta.error && meta.touched && s.floatingLabelError,
                            )}
                          >
                            Введите тип обращения
                          </span>
                          {meta.error && meta.touched && (
                            <span className={s.contactInfoSupportInputError}>{meta.error}</span>
                          )}
                        </div>
                      )}
                    </Field>
                  )}
                  <Field name="email">
                    {({ input, meta }) => (
                      <div className={s.contactInfoSupportInputWrapper}>
                        <input
                          {...input}
                          type="text"
                          className={s.contactInfoSupportInput}
                          required
                        />
                        <span
                          className={cn(
                            s.floatingLabel,
                            meta.error && meta.touched && s.floatingLabelError,
                          )}
                        >
                          Электронная почта
                        </span>
                        {meta.error && meta.touched && (
                          <span className={s.contactInfoSupportInputError}>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="name">
                    {({ input, meta }) => (
                      <div className={s.contactInfoSupportInputWrapper}>
                        <input
                          {...input}
                          type="text"
                          className={s.contactInfoSupportInput}
                          required
                        />
                        <span
                          className={cn(
                            s.floatingLabel,
                            meta.error && meta.touched && s.floatingLabelError,
                          )}
                        >
                          Ваше имя
                        </span>
                        {meta.error && meta.touched && (
                          <span className={s.contactInfoSupportInputError}>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="message">
                    {({ input, meta }) => (
                      <div className={s.contactInfoSupportInputWrapper}>
                        <textarea {...input} className={s.contactInfoSupportTextarea} required />
                        <span
                          className={cn(
                            s.floatingLabel,
                            meta.error && meta.touched && s.floatingLabelError,
                          )}
                        >
                          Сообщение
                        </span>
                        {meta.error && meta.touched && (
                          <span className={s.contactInfoSupportInputError}>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                  <button
                    className={s.contactInfoSupportButton}
                    type="submit"
                    disabled={submitting || Object.keys(errors).length > 0 || isLoading}
                  >
                    {!isLoading ? (
                      'Отправить'
                    ) : (
                      <svg className={s.spinner} viewBox="0 0 50 50">
                        <circle
                          className={s.path}
                          cx="25"
                          cy="25"
                          r="20"
                          fill="none"
                          strokeWidth="5"
                        />
                      </svg>
                    )}
                  </button>
                  {showError && (
                    <span className={s.contactInfoSupportInputError}>
                      При отправке формы возникла ошибка, пожалуйста повторите позже
                    </span>
                  )}
                  <Modal
                    isOpen={showModal}
                    contentLabel="Модальное окно"
                    onRequestClose={() => {
                      setShowModal(false);
                      form.restart();
                    }}
                    className={s.modal}
                    overlayClassName={s.modalOverlay}
                    ariaHideApp={false}
                  >
                    <p className={s.modalText}>
                      Спасибо за&nbsp;ваше обращение, служба поддержки свяжется с&nbsp;вами
                      в&nbsp;ближайшее время
                    </p>
                    <button
                      type="button"
                      aria-label="Close"
                      onClick={() => {
                        setShowModal(false);
                        form.restart();
                      }}
                      className={s.modalClose}
                    />
                  </Modal>
                </form>
              )}
            />
          </div>
        </div>
      </div>
      <a href="/РТ_LIFE_2023.pdf" target="_blank" className={cn(s.presentationLink, 'container')}>
        <button className={s.presentationLinkButton} type="button">
          Посмотреть подробную презентацию о&nbsp;приложении RT&nbsp;Life
        </button>
      </a>
    </div>
  );
};

export default ContactInfo;
