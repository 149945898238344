import React from 'react';
import s from './Exploitation.module.css';

const Information = [
  { title: 'Тип мобильного устройства', text: 'Только смартфон', id: 1 },
  { title: 'Минимальный объем доступной свободной памяти', text: 'Не менее 128 Mb', id: 2 },
  { title: 'Минимальные версии операционной системы', text: 'iOS 10, Android 6.0', id: 3 },
  { title: 'Минимальное разрешение экрана', text: '1136 x 640', id: 4 },
];

const Exploitation = () => {
  return (
    <div className={s.exploitation} id="exploitation">
      <div className="container">
        <h3 className={s.exploitationSubTitle}>Основной класс ПО:</h3>
        <p className={s.exploitationText}>06.05. Офисные приложения - Органайзеры</p>
        <h3 className={s.exploitationSubTitle}>Информация по установке приложения:</h3>
        <div className={s.exploitationText}>
          <div className={s.exploitationTable}>
            {Information.map((info) => {
              return (
                <div key={info.id} className={s.exploitationTableRow}>
                  <div className={s.exploitationTableCell}>{info.title}</div>
                  <div className={s.exploitationTableCell}>{info.text}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={s.documentList}>
          <h3 className={s.exploitationSubTitle}>Руководство пользователя:</h3>
          <div className={s.exploitationText}>
            <div className={s.document}>
              <a href="/Инструкция пользователя RT Life.pdf" target="_blank">
                <span className={s.documentIcon}>pdf</span>
                <span className={s.documentTitle}>
                  <span>Скачать</span>
                </span>
              </a>
            </div>
          </div>
        </div>
        <h3 className={s.exploitationSubTitle}>Информация по эксплуатации:</h3>

        <p className={s.exploitationText}>
          Эксплуатация осуществляется силами ООО «РТК ИТ» в соответствии с внутренними регламентами
          и документацией.
        </p>
        <h3 className={s.exploitationSubTitle}>Лицензирование ПО:</h3>
        <p className={s.exploitationText}>
          Стоимость продукта формируется динамическим путем в зависимости от требуемых
          функциональных возможностей, количества внутренних корпоративных систем, с которыми
          необходима реализации интеграции, необходимой инфраструктуры и выбранного уровня поддержки приложения.
          Запросить дополнительную информацию вы можете по почте <a href="mailto:life@rt.ru">life@rt.ru</a>.
        </p>
        <p className={s.exploitationText}>
          Продукт включен в Единый реестр российского программного обеспечения. <br />
          <a rel="noopener noreferrer" target="_blank" href="https://reestr.digital.gov.ru/reestr/2099984/">Реестровая запись № 21150 от 30.01.2024</a>.
        </p>
      </div>
    </div>
  );
};

export default Exploitation;
