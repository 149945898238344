export const localSliderData = [
  {
    order_number: 1,
    statistic: 31000,
    title: 'Активных пользователей в этом году',
    type: 'year_activity',
  },
  {
    order_number: 2,
    statistic: 5143,
    title: 'Установок приложения в этом году',
    type: 'installations',
  },
  {
    order_number: 3,
    statistic: 41,
    title: 'Оценка приложения',
    type: 'rate',
  },
  {
    order_number: 4,
    statistic: 6,
    title: 'Месяцев с запуска новой версии',
    type: 'launch_months',
  },
  {
    order_number: 0,
    statistic: 3358,
    title: 'Активных пользователей за день',
    type: 'daily_activity',
  },
];
