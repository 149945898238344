import React from 'react';

import Layout from '../components/layout';
import ScreenshotBlock from '../components/Screenshot';
import DescriptionBlock from '../components/Description';
import ExploitationBlock from '../components/Exploitation';
import FunctionsList from '../components/FunctionsList';
import Statistics from '../components/Statistics';
import StoreFeedbacks from '../components/StoreFeedbacks';
import DevelopmentPlan from '../components/DevelopmentPlan';
import ContactInfo from '../components/ContactInfo';

const IndexPage = () => {
  // useEffect(() => {
  //   document.addEventListener('scroll', () => {
  //     const scrollTop = window.scrollY;
  //     const position = Math.round(scrollTop / 7);

  //     const notebook = document.getElementById('notebook');
  //     const person = document.getElementById('person');
  //     const helpme = document.getElementById('helpme');

  //     notebook.style.transform = `translateY(-${position}px)`;
  //     person.style.transform = `translateY(-${position / 2}px)`;
  //     helpme.style.transform = `translateY(-${position / 3}px)`;
  //   });
  // });

  return (
    <Layout>
      <ScreenshotBlock />
      <DescriptionBlock />
      <FunctionsList />
      <Statistics />
      <StoreFeedbacks />
      <DevelopmentPlan />
      <ContactInfo />
      <ExploitationBlock />
    </Layout>
  );
};

export default IndexPage;
