import React from 'react';
import cn from 'classnames';
import { bool, func } from 'prop-types';
import s from './Arrow.module.css';

const Arrow = ({ back, onClick, gray, bigOffset }) => (
  <button
    type="button"
    aria-label="Arrow"
    className={cn(s.arrow, { [s.arrowBack]: back, [s.gray]: gray, [s.bigOffset]: bigOffset })}
    onClick={onClick}
  />
);

Arrow.propTypes = {
  back: bool,
  onClick: func,
  gray: bool,
  bigOffset: bool,
};

export default Arrow;
