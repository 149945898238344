import React from 'react';
import { number } from 'prop-types';

const Progressbar = ({ stepWidth }) => (
  <div>
    <svg
      width={stepWidth || 40}
      height="4"
      viewBox={`0 0 ${stepWidth || 40} 4`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="0" height="4" rx="2" fill="#273A64" />
    </svg>
  </div>
);

Progressbar.propTypes = {
  stepWidth: number,
};

export default Progressbar;
