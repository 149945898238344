import React from 'react';
import screen from '../../images/description-screen.png';
import s from './Description.module.css';

const Description = () => {
  return (
    <div className={s.description} id="description">
      <div className="container">
        <p className={s.descriptionText}>
          RT&nbsp;Life&nbsp;объединяет в&nbsp;себе функции адресной книги, справочника, новостной
          ленты и&nbsp;системы отправки заявок в&nbsp;различные службы компании. Пользователями
          RT&nbsp;Life являются все сотрудники вне зависимости от&nbsp;их&nbsp;региональной
          принадлежности, должности и&nbsp;ранга.
        </p>
        <div className={s.descriptionImages}>
          <img src={screen} className={s.screen} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Description;
